.about {
  z-index: 2;
  height: 90vh;
  display: flex;
  justify-content: center;
}
.about-inner-container {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: seashell;
}
.about-content {
  width: 40%;
}

.skills {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 2%;
  width: 45%;
  height: 80%;
}
.row2 {
  display: flex;
  justify-content: space-around;
}
.row3 {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 500px) {
  .about-inner-container {
    height: 95%;
    margin-top: 5%;
    flex-direction: column;
  }
  .about-content {
    width: 90%;
  }
  .skills {
    width: 90%;
  }
}
