.app-info {
  margin: 0 auto;
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.app-info-inner-con {
  height: 90%;
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title-and-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.logo-img {
  width: 40%;
}
.desc {
  height: 40%;
  padding-right: 5%;
  overflow-y: auto;
  scroll-behavior: smooth;
  background-color: transparent;
  color: azure;
  border: none;
}

.btn {
  margin-top: 10%;
}
@media screen and (max-width: 450px) {
  .app-info-inner-con {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .logo-img {
    width: 20%;
  }
  .btn {
    margin-top: 10px;
  }
}
@media screen and (max-height: 450px) {
  .btn {
    margin-top: 5px;
  }
  div p {
    font-size: 9px;
  }
}
