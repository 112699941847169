.header {
  background-color: black;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.header h1 {
  font-family: MontserratSubrayada-Bold;
  margin-right: 30px;
}
.link {
  padding-right: 25px;
  font-size: calc(9px + 0.8vw);
  color: white;
  text-decoration: none;
}
.link:hover {
  color: forestgreen;
  text-decoration: none;
}
@media screen and (min-width: 2000px) {
  .link {
    font-size: 28px;
  }
}
@media screen and (max-width: 1000px) {
  .header {
    padding-left: 10px;
  }
  .header h1 {
    margin-right: 15px;
  }
}
@media screen and (max-width: 500px) {
  .header h1 {
    margin-right: 10px;
  }
  .header h1 {
    font-size: 14px;
  }
  .link {
    font-size: 8px;
    padding-right: 5px;
  }
}
