.container {
  position: relative;
  z-index: 2;
  top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.button {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 35vw;
}

.button:hover {
  background: white;
  opacity: 0.9;
  color: "black";
}

.projects {
  margin-top: 30px;
  width: 50%;
}

@media (max-width: 550px) {
  .projects {
    margin-top: 30px;
    width: 50%;
  }
}
