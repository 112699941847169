.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 20;
  left: 0;
  background-image: url("../../images/matrix-code.gif");
  background-size: cover;
  z-index: 1000;
}
.initial-container {
  position: fixed;
  top: 20;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.attibution {
  position: fixed;
  bottom: 0;
  right: 0;
  color: white;
  font-size: 12px;
}
