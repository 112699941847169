.outer-container {
  z-index: 2;
  position: relative;
  top: 10vh;
  width: 50%;
  margin: 10vh auto;
  display: flex;
  justify-content: space-between;
}
.contact {
  flex: 20%;
  margin-right: 10%;
  height: 50vh;
}
.social-links {
  flex: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-links {
  width: 70%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 5vw;
}

@media (max-width: 600px) {
  .outer-container {
    flex-direction: column;
    justify-content: space-around;
  }
  .social-links {
    margin-top: 20px;
  }
  .inner-links {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    font-size: 5vh;
  }
}

@media (max-height: 450px) {
  .contact {
    flex: 75%;
  }
  .message-box {
    margin-left: 50px;
  }
  .social-links {
    flex: 10%;
  }
  .form-wrapper {
    flex-direction: row;
    justify-content: space-around;
  }
}
