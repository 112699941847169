.app-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-img-inner-container {
  margin-top: 50px;
  width: 35%;

}
@media screen and (max-width: 450px) {
  .app-img {
    width: 100%;
    margin-top: 20px;
  }
  .app-img-inner-container {
    margin: auto;
    width: 30%;
  }
}
@media screen and (max-height: 450px) {
  .app-img-inner-container {
    margin-top: 10px;
  }
}
