.skill-container {
  display: flex;
  align-items: center;
}
.static-logo {
  width: 3vw;
}
.tech-logo {
  width: 3vw;
  animation: 0.5s infinite alternate swing;
}
.tech-name {
  color: ghostwhite;
  margin-right: 1vw;
}
.tech-name:hover {
  cursor: pointer;
  color: rgb(240, 236, 14);
  text-decoration: none;
}
@media screen and (max-width: 500px){
  .static-logo{
    width: 3vh;
  }
  .tech-logo{
    width: 3vh;
  }
}

@keyframes swing {
  from {
    transform: rotate(20deg);
  }
  to {
    transform: rotate(-20deg);
  }
}
@keyframes swing {
  0% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}
