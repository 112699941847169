.project {
  background-color: black;
  border: 0.2vw solid rgb(163, 155, 155);
  border-radius: 50px;
}
.project:hover {
  background-color: rgb(163, 155, 155);
  cursor: pointer;
}

.link {
  text-decoration: none;
}

.title {
  text-align: center;
  font-size: xx-large;
}

.platform-logo {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 20%;
}

.platform-text {
  font-size: medium;
}

@media screen and (max-width: 550px) {
  .project {
    border-radius: 20px;
  }
}
