.selected {
  z-index: 2;
  position: relative;
  top: 10vh;
  height: 90vh;
}
.details-container {
  margin-top: 5vh;
  float: left;
  width: 50%;
  height: 90%;
}
.images-container {
  float: right;
  width: 50%;
}

@media screen and (max-width: 550px) {
  .details-container {
    width: 100%;
    height: 50%;
  }
  .images-container {
    width: 100%;
    height: 50%;
  }
}
