.App {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  max-width: 3000px;
}
.App::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.header {
  position: fixed;
  height: 10vh;
  width: 100vw;
  z-index: 100;
}

.background {
  position: fixed;
  width: 100vw;
  top: 10vh;
  height: 90vh;
  flex-grow: 1;
  z-index: 1;
}

.background::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

div {
  color: white;
  font-size: calc(8px + 0.8vw);
  font-family: Changa-Regular;
}
div h1 {
  font-size: calc(20px + 0.8vw);
  font-family: Changa-Medium;
}
div h2 {
  font-size: calc(16px + 0.8vw);
}
div h3 {
  font-size: calc(12px + 0.8vw);
}
@media screen and (max-width: 1000px) {
  div h1 {
    font-size: calc(18px + 0.6vw);
  }
  div h2 {
    font-size: calc(14px + 0.6vw);
  }
  div h3 {
    font-size: calc(12px + 0.6vw);
  }
}
@media screen and (max-width: 500px) {
  div h1 {
    font-size: calc(10px + 0.5vw);
  }
  div h2 {
    font-size: calc(9px + 0.2vw);
  }
  div h3 {
    font-size: calc(8px + 0.2vw);
  }
}
